<template lang="pug">
  header(itemscope, itemtype="https://schema.org/WPHeader", :class="{'quick-search-dropdown-opened': showResultsBlock}")
    meta(itemprop="name", :content="$t('layout.header.meta.name')")
    meta(itemprop="description", :content="$t('layout.header.meta.description')")
    .top-header
      top-banner-component
      lazy-hydrate(never, :trigger-hydration="hydrateContacts")
        collapse-transition
          top-header-contacts-line-component(v-show="showContacts.state", @close="onContactsHandler", :class="{opened: showContacts.state }")
      top-header-second-line(@contacts="onContactsHandler")
</template>

<script>
import { CollapseTransition } from 'vue2-transitions'
import LazyHydrate from 'vue-lazy-hydration'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'TopHeaderComponent',

  components: {
    LazyHydrate,
    CollapseTransition,
    TopHeaderShippingReturns: () =>
      import('~~/components/Layout/TopHeader/TopHeaderShippingReturns'),
    TopBannerComponent: () => import('~~/components/Layout/TopBanner'),
    TopHeaderSecondLine: () => import('./TopHeaderSecondLine'),
    TopHeaderSearchLine: () => import('./TopHeaderSearchLine'),
    TopHeaderContactsLineComponent: () => import('./TopHeaderContactsLine')
  },

  provide() {
    return {
      showContacts: this.showContacts
    }
  },

  data: () => ({
    hydrateContacts: false,
    showContacts: {
      state: false
    }
  }),

  computed: {
    ...mapState({
      menuOpen: (state) => state.app.menuOpen
    }),

    ...mapGetters({
      showResultsBlock: 'quickSearch/showResults'
    })
  },

  beforeDestroy() {
    this.$root.$off('routeChanged', this.closeContacts)
    this.$root.$off('toggle-contacts', this.onContactsHandler)
  },

  mounted() {
    this.$root.$on('routeChanged', this.closeContacts) // dirty hack for styles links from main menu
    this.$root.$on('toggle-contacts', this.onContactsHandler)
  },

  methods: {
    closeContacts() {
      this.showContacts.state = false
    },

    onContactsHandler() {
      this.hydrateContacts = true
      setTimeout(() => {
        this.showContacts.state = !this.showContacts.state
        this.$nextTick(() => {
          if (window.lazyUpdate) window.lazyUpdate()
        })
      }, 100)
    }
  }
}
</script>

<style lang="sass" scoped>
header
  +mobile
    transition: all .3s ease
    width: 100%
    position: fixed
    z-index: 2000
    background: white
    top: 0
    &.quick-search-dropdown-opened
      z-index: 2001
    &.hide
      top: -$headerHeight
  .top-header
    &__shipping-returns
      background-color: $secondary-bg-color
      padding: 7px
      margin-right: unset
      justify-content: center
      border-bottom: 1px solid $border-color
      &::v-deep
        .tooltip
          a
            color: $default-font-color
    &-contacts-line
      &.opened
        border-bottom: 1px solid $border-color
</style>
